import Api from '../utils/api'
import { calculateAge } from '../utils/utility'

import logo from '../assets/images/logo.png'

export const dataPersonFormatter = (person) => {
  return {
    ...person,
    key: person.id,
    fullname: person.fullname ? person.fullname : person.lastname + person.firstname,
    avatarurl: person.avatarurl !== '' ? person.avatarurl : logo,
    logourl: person.logourl !== '' ? person.logourl : logo,
    age: calculateAge(person.birthdate),
    birthdate: new Date(person.birthdate * 1000).toLocaleDateString()
  }
}

export const getProfile = async () => {
  return await Api.get('/me/profile').then((res) => {
    return dataPersonFormatter(res)
  })
}

export const getEntries = async (params) => {
  return await Api.get('/me/entries', params).then((res) => {
    return res
  })
}

export const getEntry = async (entryid) => {
  return await Api.get(`/me/entries/${entryid}`).then((res) => {
    return res
  })
}

export const getEntriesStats = async () => {
  return await Api.get('/me/entries/stats').then((res) => {
    return res
  })
}

export const returnEntry = async (entryid, params) => {
  return await Api.put(`/me/entries/${entryid}/return`, params).then((res) => {
    return res
  })
}

export const rejectEntry = async (entryid) => {
  return await Api.put(`/me/entries/${entryid}/reject`).then((res) => {
    return res
  })
}

// export const finalistEntry = async (entryid) => {
//     return await Api.put(`/me/entries/${entryid}/finalist`).then((res) => {
//         return res;
//     })
// }

export const postFinalist = async (entryid) => {
  return await Api.post('/me/finalists', { entryid }).then((res) => {
    return res
  })
}

export const getFinalists = async (params) => {
  return await Api.get('/me/finalists', params).then((res) => {
    return res
  })
}

export const postAward = async (entryid) => {
  return await Api.post('/me/awards', { entryid }).then((res) => {
    return res
  })
}

export const getAdwards = async (params) => {
  return await Api.get('/me/awards', params).then((res) => {
    return res
  })
}

// export const advanceAward = async (awardid) => {
//     return await Api.put(`/me/awards/${awardid}/advance`).then((res) => {
//         return res;
//     })
// }

export const putAdvance = async (awardid) => {
  return await Api.put(`/me/awards/${awardid}/advance`).then((res) => {
    return res
  })
}

export const getCategories = async (params = {}) => {
  return await Api.get('/categories', params).then((res) => {
    return res
  })
}

export const getEnums = async () => {
  return await Api.get('/settings/enums').then((res) => {
    return res
  })
}

export const signin = async (params) => {
  return await Api.get('/auth/signin', params).then((res) => {
    return res
  })
}

export const getEvents = async (params = {}) => {
  return await Api.get('/events', params).then((res) => {
    return res
  })
}

export const getrMembers = async (params) => {
  return await Api.get('/me/members', params).then((res) => {
    return res
  })
}

export const postMembers = async (data) => {
  return await Api.post('/me/members', data).then((res) => {
    return res
  })
}

export const postFinalistScore = async (finalistid, params) => {
  return await Api.post(`/me/finalists/${finalistid}/score`, params).then((res) => {
    return res
  })

}

export const getFinalist = async (finalistid) => {
  return await Api.get(`/me/finalists/${finalistid}`).then((res) => {
    return res
  })
}

export const postFianlistsSync = async () => {
  return await Api.post('/me/finalists/sync').then((res) => {
    return res
  })
}

// 视频转m3u8
export const toM3u8Api = async (entryid, fileid) => {
  return await Api.get(`/tool/tom3u8/${entryid}/${fileid}`).then((res) => {
    return res
  })
}

// 违规选项
export const violationsApi = async () => {
  return await Api.get('/enums/violations').then((res) => {
    return res
  })
}

// 一键提交不可修改评分
export const putConfirmApi = async () => {
  return await Api.put('/me/finalists/confirm').then((res) => {
    return res
  })
}

// 批量评分
export const postFianlistsScoresSync = async (params) => {
  return await Api.post('/me/finalists/scores', params).then((res) => {
    return res
  })
}