import React,{ useEffect, useState, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar'
import ModalSidebar from '../overlays/Modal/Sidebar'
import Header from './Header'
import Loader from '../components/Loader'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline'
import { getProfile } from '@/api/judge'
import { store } from '@/store/index'
import { Settings } from '@/utils/settings'

function Layout() {
  const [profile, setProfile] = useState({})
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [sidebarCollapse, setSidebarCollapse] = useState(false)

  useEffect(() => {
    getProfile().then((res) => {
      setProfile(res)
      Settings.profile = res
      store.dispatch({
        type: 'set_profile',
        payload: res
      })
    })
  }, [])

  return (
    <>
      {/* Mobile sidebar */}
      {sidebarOpen && (
        <ModalSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      )}

      {/* Static sidebar for desktop */}
      <div className={
        sidebarCollapse ?
        "hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-20 lg:flex-col ease-in-out duration-300" :
        "hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col ease-in-out duration-300"
      }
      >
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <Sidebar sidebarCollapse={sidebarCollapse} setSidebarCollapse={setSidebarCollapse} profile={profile} />

        <button onClick={() => setSidebarCollapse(!sidebarCollapse)}  className="w-full group flex gap-x-3 px-6 p-2 text-sm font-semibold leading-6 text-indigo-200 bg-purple-800 hover:text-white">
          {
            sidebarCollapse ?
            (<ChevronDoubleRightIcon className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white" aria-hidden="true"/>) : 
            (<ChevronDoubleLeftIcon  className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white" aria-hidden="true"/>)
          }
          <span className={sidebarCollapse ? 'sr-only ease-out duration-300' : ''}>{sidebarCollapse ? '展开边栏' : '收起边栏'}</span>
        </button>
      </div>

      <div className={
        sidebarCollapse ?
        "lg:ml-20 h-full flex flex-col ease-in-out duration-300 bg-white" :
        "lg:ml-64 h-full flex flex-col ease-in-out duration-300 bg-white"
      }
      >
        <Header setSidebarOpen={setSidebarOpen} profile={profile} />
        <main className="py-2 px-2 overflow-y-auto flex-1">
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </main>
      </div>
    </>
  )
}

export default Layout