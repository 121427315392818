import { legacy_createStore as createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // 使用 localStorage 或 sessionStorage
import { UserReducer } from '@/store/reducers/UserReducer'

// 定义reducer
const reducers = combineReducers({
  UserReducer
})

const persistConfig = {
  key: 'judge', // 可选，用于在localStorage中设置键名
  storage // 存储引擎，默认使用localStorage
}

// 创建持久化reducer
const persistedReducer = persistReducer(persistConfig, reducers)

// 创建store并持久化store
const store = createStore(persistedReducer)
const persistor = persistStore(store)

export {
  store,
  persistor
}