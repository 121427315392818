import React from 'react'
import { NavLink } from 'react-router-dom'
import {
    HomeIcon,
    ArrowPathIcon,
    TrophyIcon,
} from '@heroicons/react/24/outline'
import { postFianlistsSync } from '@/api/judge'
import logo from '@/assets/images/logo.png'

const navigationFirst = [
    {
        id: 'entry',
        name: '作品',
        group: [
            { name: '参赛作品',   href: '/first', icon: TrophyIcon, current: false },
        ]
    },
    {
        id: 'options',
        name: '操作',
        showOther: true,
        group: []
    }
]

const navigationFinally = [
    {
        id: 'entry',
        name: '作品',
        group: [
            { name: '入围作品',   href: '/finalists', icon: TrophyIcon, current: false },
        ]
    },
    {
        id: 'options',
        name: '操作',
        showOther: true,
        group: []
    }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Sidebar({ sidebarCollapse, setSidebarCollapse, profile }) {
    const [loading, setLoading] = React.useState('')

    const handleSyncEntries = () => {
        setLoading('animate-spin')
        postFianlistsSync().then(res => {
            if (res.code === 0) {
                alert('同步任务已启动, 请稍后刷新页面查看最新数据')
             } else {
                alert(res.msg)
             }
        }).catch(err => {
            alert('同步失败, 请检查网络连接')
        }).finally(() => {
            setLoading('')
        })
    }

    const showMenu = () => {
        if (profile?.['auth_type'] === 5) {
            return navigationFirst
        } else if (profile?.['auth_type'] === 10) {
            return navigationFinally
        }
        return navigationFirst
    }
    
    return (
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-purple-600 px-6">
            <div className="flex h-16 shrink-0 items-center">
                <img
                    className="h-8 w-auto"
                    src={logo}
                    alt="Your Company"
                />
                <span className={sidebarCollapse ? 'sr-only ease-out duration-300' : 'ml-2 text-white text-lg text-bold'}>好创意大赛</span>
            </div>

            <nav className="flex flex-1 flex-col">
                <ul className="flex flex-1 flex-col gap-y-7">
                    { showMenu().map((item) => (
                        <li key={item.id}>
                            { item.name && <div className="text-xs font-semibold leading-6 text-purple-200">{ item.name }</div> }
                            <ul className="-mx-2 space-y-1">
                                { item.showOther &&
                                    <li>
                                        <button 
                                            onClick={ handleSyncEntries }
                                            className="w-full hover:text-white bg-blue-600 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-purple-200"
                                        >
                                            <ArrowPathIcon className={`h-6 w-6 shrink-0 text-purple-200 group-hover:text-white ${loading} `} aria-hidden="true" />
                                            <span className={sidebarCollapse ? 'sr-only ease-out duration-300' : ''}>同步作品</span>
                                        </button>
                                    </li>
                                }
                                { item.group.map((sub) => (
                                    <li key={sub.href}>
                                        <NavLink
                                            to={sub.href}
                                            className={({ isActive }) =>
                                                isActive ?
                                                "hover:text-white hover:bg-purple-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold bg-purple-700 text-white" :
                                                "hover:text-white hover:bg-purple-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-indigo-200"
                                            }
                                            title={sub.name}
                                        >
                                            <sub.icon
                                                className={classNames(
                                                    sub.current ? 'text-white' : 'text-purple-200 group-hover:text-white',
                                                    'h-6 w-6 shrink-0'
                                                )}
                                                aria-hidden="true"
                                                title={sub.name}
                                            />
                                            <span className={sidebarCollapse ? 'sr-only ease-out duration-300' : ''}>{sub.name}</span>
                                        </NavLink>
                                    </li>
                                )) }
                            </ul>
                        </li>
                    )) }
                </ul>
            </nav>
        </div>
    )
}
  
export default Sidebar